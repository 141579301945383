import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../components/Layout"
import "./cookies.css"

const Cookies = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const seo = {
    title: t("cookies.seo.title"),
    description: t("cookies.seo.description"),
    lang: language,
  }

  return (
    <Layout datos={seo}>
      <div className="cookies md:mt-32 flex flex-col justify-center items-center container max-w-4xl p-10 ">
        <div className="p-5 text-sm">
          <h1 className="text-azul-dark font-bold underline pt-5 text-2xl">
            {t("cookies.titulo")}
          </h1>
          <h2>{t("cookies.t1")}</h2>
          <div dangerouslySetInnerHTML={{ __html: t("cookies.p1") }} />
          <h2>{t("cookies.t2")}</h2>
          <p> {t("cookies.p2")} </p>
          <h2>{t("cookies.t3")}</h2>
          <p dangerouslySetInnerHTML={{ __html: t("cookies.p3") }} />
          <h2>{t("cookies.t4")}</h2>
          <p dangerouslySetInnerHTML={{ __html: t("cookies.p4") }} />
          <h2>{t("cookies.t5")}</h2>
          <p
            className="text-azul-dark mb-10"
            dangerouslySetInnerHTML={{ __html: t("cookies.p5") }}
          />
        </div>

        <div className="mb-10 px-5 text-sm rounded">
          <h1 className="text-azul-dark font-bold underline text-2xl">
            {t("privacidad.titulo")}
          </h1>
          <div
            className="mt-5 text-azul-dark"
            dangerouslySetInnerHTML={{ __html: t("privacidad.p1") }}
          />
          <div
            className="mt-5 text-azul-dark"
            dangerouslySetInnerHTML={{ __html: t("privacidad.p2") }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Cookies

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
